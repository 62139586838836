.crop-uploader-container {
    margin-bottom: 24px;
   
    .upload-placeholder {
        border: 1px dashed #ccc;
        border-radius: 4px;
        
        .uploading-input {
            height: 100%;
            position: relative;
            overflow: hidden;
            color: #575757;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            
            .input-section {
                width: 100%;
                padding: 16px;
                text-align: center;
                font-size: 14px;
                margin:0 -15px;

                .file-input-overlay {
                    z-index: 9;   
                    width: 100%;
                    height: 100%;  
                    top: 0;
                    left: 0; 
                    position: absolute;
                    opacity: 0;

                    .file-input {
                        position: relative;
                        width: 100%;
                        font-size: 80px;
                        opacity: 0;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }
    }

    .cropper-container {
        display: block;
        .pp-cropper-container {
            height: 350px;
            margin: 0 auto;
            border: 1px dashed #ccc;
            .cropper-actions {
                display: flex;
                justify-content: center;
                margin-top: -48px;
            }
            .file-input-wrapper {
                display: flex;
                overflow: hidden;
                position: relative;
                cursor: pointer;


                input[type="file"] {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    opacity: 0;
                }
                label {
                    font-size: 14px;
                    color: #ffffff;
                    font-weight: 500;
                    border-radius: 48px;
                    display: inline-block;
                    cursor: pointer;
                }
            }
        }
    }

    .document-cropper-section {
        align-items: center;

        .document-desc {
            font-weight: bold;
        }

        .document-options {
            display: flex;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
            z-index: 40;
            position: absolute;
            bottom: 5px;

            .toggle-button-groups {
                justify-content: center;
                flex-wrap: wrap;
            }

            .btn-custom-file-uploder {
                background-color: #3f51b5;
                color: white;
                padding: 0.3rem;
                font-family: sans-serif;
                height: 1.5rem;
                border-radius: 0.3rem;
                cursor: pointer;
                margin-top: 0.2rem;
                margin-left: 0.1rem;
                border-radius: 0.3rem;
                text-align: center;

                z-index: 60;
                width:100px;
                position:relative;
                margin-left: 5px;
            }
            .change-document-input {
                visibility: hidden;
                z-index: 40;
                width: 0;
            }
        }
    }
}
  