.edgro-logo {
    float: right;
    margin: 30px 20px 20px 0px; 
}

.standard-kyc-container {
    align-items: center;
    justify-content: center;
    min-height: 30vh;
    padding: 10% 5%;
    border-width: 10px;

    @media only screen and (max-width: 600px) {
        padding: 20% 5%;
    }

    .kyc-paper {
        padding: 1%;
        width: 70%;
        max-width: 600px;

        @media only screen and (max-width: 600px) {
            width: 90%;
        }

        .upload-selection-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 20px;

            .title {
                margin: 10px 0px;
            }

            .document-type-input {
                margin: 10px;
                width: 180px;
                margin-left: auto;
            }

            .upload-button {
                margin: 10px;
                float: right;
                width: 200px;
                margin-left: auto;
            }
            .checked-button {
                margin: 10px;
                float: right;
                color: white;
                background-color: green;
                margin-left: auto;
            }

            @media only screen and (max-width: 600px) {
                .title {
                    margin: 20px auto 20px 0px;
                }

                .document-type-input {
                    margin: 10px 0px;
                    width: 100%;
                }
    
                .upload-button {
                    margin: 10px 0px;
                    width: 100%;
                }
                .checked-button {
                    margin: 10px 0px;
                }
            }
        }
    }
}

.document-input-modal {
    position: absolute;
    overflow: scroll;
    height: 100%;
    display: block;

    .modal-card {
        height: 100%;
        overflow: auto; 

        .header-card {
            text-align: center;
            background-color: #f9f9f9;
            box-shadow: 1px 1px 2px 2px rgba(0,0,0,.1);

            .close-button {
                margin: 5px;
                margin-left: 10px;
                float: right;
            }
        }
    }

    .document-upload-section {
        width: 100%;
        position: relative;
    }
}